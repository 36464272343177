import apiTool from '@/command/apiTool'
import { getAction, postAction } from '@/command/netTool'
import { Button } from 'ant-design-vue'
import DrawerForm from '@/components/DrawerForm'

let taskData = []
let taskDataVIP = []
async function onFetchInit() {
  const [type1, type2, taskDataVIPCopy, taskDataCopy] = await Promise.all(
    [
      '/config/farmVipTask/list?type=1',
      '/config/farmVipTask/list?type=2',
      '/config/farmVipInfo/list',
      '/admin/dict/code/vip_task_mark'
    ].map((e, i) => getAction(e, {}, i == 3 ? '/bpi' : '/api'))
  )
  taskDataVIP = taskDataVIPCopy.data.map(e => {
    return {
      ...e,
      value: e.type
    }
  })
  taskData = taskDataCopy.data.map(e => {
    return {
      ...e,
      value: e.id
    }
  })

  return {
    typeData: {
      type1: type1.data,
      type2: type2.data
    }
  }
}

function onOpen(form, getDetail) {
  //   console.log(typeof form.taskLevel)
  //   if (typeof form.taskLevel == 'string') {
  //     let a = (form.taskLevel || '').split(',')
  //     form.taskLevel = a
  //   }
  apiTool.showDrawer({
    title: '编辑',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      form: { ...form },
      formProps: {
        layout: 'horizontal',
        cols: 24,
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      data: form => getDrawerForm({ form, getDetail })
    },
    success: ({ data, setHidden }) => {
      postAction('/config/farmVipTask/update', {
        ...data,
        taskLevel: (data.taskLevel || []).toString(),
        status: data.status == '0' ? '1' : '0'
      }).then(() => {
        getDetail()
        setHidden()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function createForm({ form, getDetail }, indexID) {
  apiTool.showDrawer({
    title: '编辑',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      form: { ...form },
      formProps: {
        layout: 'horizontal',
        cols: 24,
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      data: form => getDrawerForm({ form, getDetail }, indexID)
    },
    success: ({ data, setHidden }) => {
      postAction('/config/farmVipTask/add', {
        ...data,
        taskLevel: (data.taskLevel || []).toString(),
        status: data.status == '0' ? '1' : '0',
        type: indexID
      }).then(() => {
        getDetail()
        setHidden()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function getDrawerForm({ form }) {
  const formArr = [
    {
      title: '基本信息',
      form: [
        {
          name: '标题',
          type: 'input',
          labelAlign: 'left',
          key: 'title'
        },
        {
          name: '排序',
          type: 'inputNumber',
          labelAlign: 'left',
          key: 'sort'
        }
      ]
    },
    {
      title: '任务',
      form: [
        {
          name: '任务',
          type: 'select',
          labelAlign: 'left',
          key: 'taskMark',
          typeData: taskData
        },
        {
          name: '等级',
          type: 'selectMode',
          labelAlign: 'left',
          key: 'taskLevel',
          props: {
            mode: 'multiple'
          },
          typeData: taskDataVIP
        }
      ]
    },
    {
      title: '积分获取量',
      form: [
        {
          name: '获取方式',
          type: 'input',
          props: {
            addonBefore: '每消费',
            addonAfter: '元=1积分'
          },
          key: 'pointRate',
          display: form.taskMark == '2'
        },
        {
          name: '获取方式',
          type: 'input',
          props: {
            addonAfter: '次'
          },
          key: 'eachPoint',
          display: form.taskMark != '2'
        },
        {
          name: '获取上限',
          type: 'input',
          props: {
            addonAfter: '分'
          },
          key: 'maxPoint',
          display: true
        },
        {
          name: '上限重置',
          type: 'select',
          key: 'resetType',
          typeData: [
            {
              name: '日',
              value: '1'
            },
            {
              name: '周',
              value: '2'
            },
            {
              name: '月',
              value: '3'
            },
            {
              name: '年',
              value: '4'
            },
            {
              name: '不重置',
              value: '0'
            }
          ],
          display: true
        },
        // {
        //   name: '按钮名称',
        //   type: 'input',
        //   key: 'buttonName',
        //   display: true
        // }
        {
          name: '跳转按钮',
          type: 'radioButton',
          key: 'buttonShow',
          typeData: [
            {
              name: '显示',
              value: '0'
            },
            {
              name: '不显示',
              value: '1'
            }
          ],
          display: true
        },
        {
          name: '默认名称',
          type: 'input',
          labelAlign: 'left',
          key: 'buttonName',
          display: true
        },
        {
          name: '完成名称',
          type: 'input',
          labelAlign: 'left',
          key: 'completeButtonName',
          display: true
        },
        {
          name: '跳转地址',
          type: 'input',
          labelAlign: 'left',
          key: 'taskLink',
          display: true
        }
      ].filter(e => e.display)
    },
    {
      title: 'ICON',
      form: [
        {
          type: 'iconUpload',
          typeData: [
            {
              key: 'image2',
              desc: '@2x   xhdpi <br/>上传尺寸100*100px'
            },
            {
              key: 'image3',
              desc: '@3x   xxhdpi <br/>上传尺寸150*150px'
            }
          ]
        }
      ]
    }
  ]
  return formArr
}

export default function({ getDetail, create }) {
  return {
    type: 'tableGroup',
    edit: '/config/farmVipTask/update',
    create: '/config/farmVipTask/add',
    params: {
      type: '1'
    },
    onFetchInit,
    data: [
      {
        key: 'type1',
        showHeader: true,
        showFootButton: true,
        on: {
          handleAddData: () => createForm({ create, getDetail }, 1)
        },
        columns: [
          {
            dataIndex: 'title',
            title: '新手任务',
            customRender: function customRender(text, records, index, h) {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <img
                    src={records.image2 || records.image3}
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px'
                    }}
                  />
                  <span>{text}</span>
                </div>
              )
            }
          },
          {
            dataIndex: 'pointRate',
            title: '积分',
            customRender: function customRender(text) {
              return `每消费${text}元=1积分`
            }
          },
          {
            dataIndex: 'maxPoint',
            title: '获取上限',
            customRender: function customRender(text) {
              return text
            }
          },
          {
            dataIndex: 'resetType',
            title: '周期',
            customRender: function customRender(text, records) {
              return ['日', '周', '月', '年'][Number(text)]
            }
          },
          {
            dataIndex: 'status',
            title: '任务状态',
            type: 'badge',
            render(text) {
              return {
                name: text == '0' ? '上架' : '下架',
                color: text == '0' ? 'rgb(92,180,98)' : 'rgb(204,60,30)'
              }
            }
          },
          {
            title: '操作',
            customRender: function customRender(text, records, index, h) {
              return (
                <div>
                  <Button
                    ghost={true}
                    type="primary"
                    style={{ marginRight: '10px' }}
                    onClick={() =>
                      postAction('/config/farmVipTask/update', {
                        ...records,
                        status: records.status == '0' ? '1' : '0'
                      }).then(getDetail)
                    }
                  >
                    {records.status == '0' ? '下架' : '上架'}
                  </Button>
                  <Button ghost={true} type="primary" onClick={() => onOpen(records, getDetail)}>
                    编辑
                  </Button>
                </div>
              )
            }
          }
        ]
      },
      {
        key: 'type2',
        styles: {
          marginTop: '20px',
          overflow: 'hidden'
        },
        showHeader: true,
        showFootButton: true,
        on: {
          handleAddData: () => createForm({ create, getDetail }, 2)
        },
        columns: [
          {
            dataIndex: 'title',
            title: '每日任务',
            customRender: function customRender(text, records, index, h) {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <img
                    src={records.image2 || records.image3}
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px'
                    }}
                  />
                  <span>{text}</span>
                </div>
              )
            }
          },
          {
            dataIndex: 'pointRate',
            title: '积分',
            customRender: function customRender(text) {
              return `每消费${text}元=1积分`
            }
          },
          {
            dataIndex: 'maxPoint',
            title: '获取上限',
            customRender: function customRender(text) {
              return text
            }
          },
          {
            dataIndex: 'resetType',
            title: '周期',
            customRender: function customRender(text, records) {
              return ['日', '周', '月', '年'][Number(text)]
            }
          },
          {
            dataIndex: 'status',
            title: '任务状态',
            type: 'badge',
            render(text) {
              return {
                name: text == '0' ? '上架' : '下架',
                color: text == '0' ? 'rgb(92,180,98)' : 'rgb(204,60,30)'
              }
            }
          },
          {
            title: '操作',
            customRender: function customRender(text, records, index, h) {
              return (
                <div>
                  <Button
                    ghost={true}
                    type="primary"
                    style={{ marginRight: '10px' }}
                    onClick={() =>
                      postAction('/config/farmVipTask/update', {
                        ...records,
                        status: records.status == '0' ? '1' : '0'
                      }).then(getDetail)
                    }
                  >
                    {records.status == '0' ? '下架' : '上架'}
                  </Button>
                  <Button ghost={true} type="primary" onClick={() => onOpen(records, getDetail)}>
                    编辑
                  </Button>
                </div>
              )
            }
          }
        ]
      }
    ]
  }
}
